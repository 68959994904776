import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { StyledEngineProvider, ThemeProvider } from "@material-ui/core/styles";
import theme from "./config/theme";
import { CssBaseline } from "@material-ui/core";
import { useEffect } from "react";
import { DOMAIN } from "config/constantes";

const Index = () => {
  useEffect(() => {
    process.env.NODE_ENV !== "development" && (!DOMAIN.includes("localhost")) && (!window.location.origin.includes("192.168")) && (document.domain = DOMAIN);
  }, []);
  return (
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline>
            <App />
          </CssBaseline>
        </ThemeProvider>
      </StyledEngineProvider>
    </React.StrictMode>
  );
};
ReactDOM.render(<Index />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
