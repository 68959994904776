import React from "react";
import GameCanvas from "./components/gameCanvas";
import EndModal from "./components/modales/endModal";
import AnswerModal from "./components/modales/answerModal";
import Score from "./components/score";
import StartModal from "components/modales/startModal";

const App = () => {
  return (
    <>
      <AnswerModal />
      <StartModal/>
      {/* <EndModal /> */}
      <Score />
      <GameCanvas />
    </>
  );
};

export default App;
