import { Box, IconButton, Typography } from "@material-ui/core";
import React from "react";
import { useEffect, useState } from "react";
import { useItems } from "../../config/hooks/items";
import { useSpring, useSprings, animated } from "@react-spring/web";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import Timer from './gameTimer'
const AnimBox = animated(Box);
const Score = () => {
  const { foundItems, allItems: items } = useItems();
  const [open, setOpen] = useState(false);

  const [transi] = useSpring(
    () => ({
      from: { x: -150 },
      x: open ? 0 : -100,
    }),
    [open]
  );
  const bounce = useSprings(
    foundItems.length,
    foundItems.map(
      (item, index) => ({
        from: { transform: `scale(0)` },
        transform: open ? "scale(1)" : "scale(0)",
        config: {
          tension: 800,
          mass: 1,
          friction: 20,
        },
        delay: 300 + index * 200,
      }),
      [foundItems?.length, open]
    )
  );
  useEffect(() => {
    setOpen(foundItems?.length > 0);
  }, [foundItems]);

  return (
    <AnimBox
      style={transi}
      zIndex={1}
      position="fixed"
      top={20}
      left={20}
      width={80}
    >
      <AnimBox
        p={2}
        boxShadow={4}
        width="100%"
        sx={{
          // position: "absolute",
          borderRadius: 3,
          backgroundColor: "primary.main",
          zIndex: 3,
          color: "white",
          width: "100%",
          top: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box my={1}>
          <Timer />
          <Typography variant="h5" align="center">
            {foundItems?.length}/{items?.length}
          </Typography>
        </Box>
        {bounce.map((transi, i) => (
          <AnimBox
            key={i}
            style={transi}
            m={1}
            borderRadius={32}
            width={35}
            height={35}
            boxShadow={2}
            sx={{
              backgroundImage: `url(/assets/items/${
                items?.[foundItems[i]].file
              })`,
              backgroundSize: "cover",
            }}
          />
        ))}
      </AnimBox>
      <Box position="absolute" zIndex={5} right={-25} top={10}>
        <IconButton
          sx={{
            color: open ? "primary.main" : "white",
            backgroundColor: open ? "white" : "primary.main",
          }}
          onClick={() => setOpen((o) => !o)}
        >
          {open ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </Box>
    </AnimBox>
  );
};

export default Score;
