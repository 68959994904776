import { linesSelector, setValueSelector, useStore } from "../store";

const useLines = () => {
  const lines = useStore(linesSelector);
  const setValue = useStore(setValueSelector);
  const setLines = (lines) => {
    setValue("lines", lines);
  };
  const addLine = (line) => {
    const newLines = [...lines, line];
    setLines(newLines);
  };
  const clearLines = () => {
    setLines([]);
  };
  return { lines, addLine, setLines, clearLines };
};

export default useLines;
