import {
  setValueSelector,
  useStore,
  finalTimerSelector,
  endSelector,
  incrementTimerSelector,
  startedSelector,
} from "../store";
import { useItems } from "./items";
import useLines from "./lines";

const useGame = () => {
  const { clearLines } = useLines();
  const { clearItems } = useItems();
  const finalTimer = useStore(finalTimerSelector);
  const incrementTimer = useStore(incrementTimerSelector);
  const setValue = useStore(setValueSelector);
  const isEnd = useStore(endSelector);
  const isStarted = useStore(startedSelector);
  const setFinalTimer = (secs) => {
    setValue("finalTimer", secs);
  };

  const endGame = () => {
    setValue("end", true);
    window?.parent?.iframeEvent?.({
      type: 'game',
      res: "success",
      id: "excavation",
      score: finalTimer,
      redirect: {
        url: '/'
      }
    });
  };

  const startGame = () => {
    setValue("started", true);
  };

  const resetGame = () => {
    clearItems();
    clearLines();
    setFinalTimer(0);
  };

  const exitGame = () => {
    console.log("exit");
    window?.parent?.exitGame?.({ id: "excavation" });
  };

  return {
    finalTimer,
    setFinalTimer,
    endGame,
    isEnd,
    resetGame,
    incrementTimer,
    exitGame,
    isStarted,
    startGame,
  };
};

export default useGame;
