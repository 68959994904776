import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Modal } from "@material-ui/core";
import { Fade } from "@material-ui/core";
import { Box } from "@material-ui/core";
import React from "react";
import useGame from "../../config/hooks/game";
import { Duration } from "luxon";

const StartModal = () => {
  const { isStarted, startGame } = useGame();

  return (
    <Box position="fixed" top={0}>
      <Modal open={!isStarted} onClose={startGame}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            pointerEvents: "none",
          }}
        >
          <Fade
            in={!isStarted}
            timeout={{ appear: 2600, enter: 600, leave: 600 }}
          >
            <Box
              p={3}
              boxShadow={2}
              sx={{
                width:'90%',
                maxWidth: 700,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                backgroundColor: "background.paper",
                // width: "70%",
                borderRadius: 3,
                pointerEvents: "initial",
              }}
            >
              <Box flex={1} my={1} sx={{ whiteSpace: "pre-line" }}>
                <Typography variant="h4" color="primary" align="center">
                  Jeu de fouilles
                </Typography>
                <Typography variant="body1" my={2} align='center'>
                  Découvre en grattant la plaine, près du puits, ce qu’en 2001
                  des archéologues ont découvert.
                </Typography>
              </Box>

              <Box flex={1}>
                <Button onClick={startGame} variant="contained">
                  Commencer
                </Button>
              </Box>
            </Box>
          </Fade>
        </Box>
      </Modal>
    </Box>
  );
};

export default StartModal;
