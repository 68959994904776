import { useMemo } from "react";
import {
  addToFoundSelector,
  foundSelector,
  itemIdSelector,
  setValueSelector,
  resetSelector,
  useStore,
} from "../store";

const getItems = (base, width, height) => [
  {
    name: "les fondations du manoir",
    file: "archi.png",
    comments: [
      {
        file: "archi.webp",
        // title: "test",
        description:
          "Près du puits, encore utilisé au XIXᵉ siècle, des fouilles menées à partir de 2001 ont permis de mettre au jour les fondations du manoir seigneurial de Tatihou. Il était constitué de deux bâtiments en équerre. Tout autour, des reliefs, encore visibles aujourd’hui, correspondent à de longues buttes de terre cultivées et plantées de vergers, appelées billons. Le manoir fut démantelé intégralement lors du rachat de l’île et la mise en défense de la Hougue par les gens de Louis XIV.",
      },
      { file: "1.webp", description: "Plan de la Hougue, D. Laisné, 1693 " },
      {
        file: "2.webp",
        description:
          "Hypothèse de restitution du manoir de l’île. Illustration Vincent Ladune",
      },
    ],
    width: 0.3 * base,
    height: 0.3 * base,
    x: base * 0.38,
    y: base * 0.28,
  },
];

const useItems = (id, dimensions) => {
  const { base, width, height } = dimensions ?? {};

  const reset = useStore(resetSelector);
  const itemId = useStore(itemIdSelector);
  const setValue = useStore(setValueSelector);
  const foundItems = useStore(foundSelector);
  const addToFound = useStore(addToFoundSelector);
  const isFound = useMemo(() => foundItems.includes(id), [foundItems, id]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allItems = useMemo(() => getItems(base, width, height), [reset]);
  const data = useMemo(() => allItems[itemId], [itemId, allItems]);

  const setItem = (id) => {
    setValue("itemId", id);
  };
  const findItem = (id) => {
    addToFound(id);
  };
  const clearItems = () => {
    setValue("itemId", null);
    setValue("found", []);
    setValue("reset", !reset);
    setValue("end", false);
  };

  return {
    setItem,
    itemId,
    data,
    isFound,
    findItem,
    foundItems,
    allItems,
    clearItems,
  };
};
export default getItems;
export { useItems };
