import create from "zustand";

const useStore = create((set) => ({
  itemId: null,
  found: [],
  lines: [],
  reset: false,
  end: false,
  started: false,
  finalTimer: 0,
  incrementTimer: () => set(s => ({finalTimer : s.finalTimer+1})),
  setValue: (key, val) => set({ [key]: val }),
  addToFound: (id) => set((state) => ({ found: [...state.found, id] })),
}));

const setValueSelector = (s) => s.setValue;
const itemIdSelector = (s) => s.itemId;
const foundSelector = (s) => s.found;
const addToFoundSelector = (s) => s.addToFound;
const linesSelector = (s) => s.lines;
const resetSelector = (s) => s.reset;
const endSelector = (s) => s.end;
const startedSelector = (s) => s.started;
const finalTimerSelector = (s) => s.finalTimer;
const incrementTimerSelector = (s) => s.incrementTimer

export {
  useStore,
  setValueSelector,
  itemIdSelector,
  foundSelector,
  addToFoundSelector,
  linesSelector,
  resetSelector,
  endSelector,
  finalTimerSelector,
  incrementTimerSelector,
  startedSelector,
};
