import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Image } from "react-konva";
import useImage from "use-image";
import { useItems } from "../config/hooks/items";

const Item = forwardRef(({ file, width, height, x, y, name, id }, ref) => {
  const [counter, setCounter] = useState(0);
  const countRef = useRef();
  const { setItem, isFound, findItem } = useItems(id);
  const [image] = useImage(`/assets/items/${file}`, "Anonymous");

  // on reset le compteur de pixel au restart du jeu (cad => nouvelle ref d'item)
  useEffect(() => {
    countRef.current = 0;
  }, [ref]);

  //verifie le nombre de pixels transparent sur la même zone que l'image
  const verify = (ctx) => {
    if (isFound) return;
    let count = countRef.current ?? 0;
    //capture de la zone d'image identique à l'ITEM / corrigée par le pixelRatio du device
    const ratio = ctx.canvas.pixelRatio;
    const imageData = ctx.getImageData(x * ratio, y * ratio, width, height);
    //conversion des data de pixels en tableau 32bits pour meilleurs perfs.
    const pixels = new Uint32Array(imageData.data.buffer);
    for (let i = 0; i < pixels.length; i += 1) {
      //incrémente le compte si pixel est transparent
      if (pixels[i] === 0) {
        countRef.current = count++;
      }
    }
    //la boucle for est théoriquement plus rapide que le map ci dessous
    // pixels.map((p) => p === 0 && (countRef.current = count++));
    setCounter(count);
  };

  //permet l'accès à la fonction verify depuis le pcomponsant parent avec passage de ctx
  useImperativeHandle(ref, () => ({
    verifyItem(ctx) {
      verify(ctx);
    },
  }));

  //vérification du % effacé
  useEffect(() => {
    if (!isFound && counter / (width * height) >= 0.8) {
      setItem(id);
      findItem(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  return (
    <Image
      perfectDrawEnabled={false}
      image={image}
      width={width}
      height={height}
      x={x}
      y={y}
      listening={false}
    />
  );
});

export default Item;
