import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Modal } from "@material-ui/core";
import { Fade } from "@material-ui/core";
import { Box } from "@material-ui/core";
import React, { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import useGame from "../../config/hooks/game";
import { useItems } from "../../config/hooks/items";

const AnswerModal = () => {
  const [open, setOpen] = useState(false);
  const { data, itemId, allItems, foundItems } = useItems();

  const { endGame } = useGame();
  const [commentIndex, setCommentIndex] = useState(0);
  const isEnd = useMemo(
    () => allItems.length === foundItems.length,
    [allItems, foundItems]
  );

  useEffect(() => {
    let timeout;
    timeout = itemId !== null && setTimeout(() => setOpen(true), 600);
    return () => {
      clearTimeout(timeout);
    };
  }, [itemId]);

  const handleClick = () => {
    if (data?.comments?.length > commentIndex + 1) {
      setCommentIndex((c) => c + 1);
      return;
    } else {
      // isEnd && window.location.assign('https://tatihougo.app')
      isEnd && endGame();
      setCommentIndex(0);
      setOpen(false);
    }
  };
  return (
    <Box position="fixed" top={0}>
      <Modal open={open} onClose={handleClick}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            pointerEvents: "none",
          }}
        >
          {data ? (
            <Fade in={open} timeout={{ appear: 2600, enter: 600, leave: 600 }}>
              <Box
                p={3}
                boxShadow={2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: "background.paper",
                  width: "90%",
                  maxWidth: 700,
                  borderRadius: 3,
                  pointerEvents: "initial",
                  height: "95%",
                  maxHeight: 800,
                }}
              >
                <Box my={1} sx={{ whiteSpace: "pre-line" }}>
                  <Typography
                    variant="h4"
                    color="primary"
                    align="center"
                    style={{ lineHeight: "1.2rem" }}
                    mb={2}
                  >
                    Bravo! {`\n\n`}Vous venez de découvrir {data.name}
                  </Typography>
                </Box>
                {data?.comments?.[commentIndex] && (
                  <Box
                    flex={1}
                    my={1}
                    // maxHeight='70vh'
                    // height={'100%'}
                    width="100%"
                    position="relative"
                    overflow="hidden"
                    display={"contents"}
                    // overflow={"auto"}
                  >
                    <img
                      alt="commentImage"
                      width="100%"
                      height="100%"
                      src={`/assets/comments/${data?.comments?.[commentIndex]?.file}`}
                      style={{
                        height: "30vh",
                        maxHeight: "50%",
                        objectFit: "contain",
                        // maxWidth: "100%",
                      }}
                    />
                    <Box p={2} overflow="auto" height="100%">
                      <Typography align="center" variant="h6" color="primary">
                        {data?.comments?.[commentIndex]?.title}
                      </Typography>
                      <Typography align="center">
                        {data?.comments?.[commentIndex]?.description}
                      </Typography>
                    </Box>
                  </Box>
                )}

                <Box my={1}>
                  <Button onClick={handleClick} variant="contained">
                    {data?.comments?.length > commentIndex + 1
                      ? "Suivant"
                      : isEnd
                      ? "Terminer"
                      : "Continuer"}
                  </Button>
                </Box>
              </Box>
            </Fade>
          ) : null}
        </Box>
      </Modal>
    </Box>
  );
};

export default AnswerModal;
