import { useState, useEffect, useRef, createRef, useMemo } from "react";
import { Layer, Stage, Line, Group } from "react-konva";
import CoverImage from "./coverImage";
import DragCircle from "./dragCircle";
import Item from "./item";
import { useItems } from "../config/hooks/items";
import useLines from "../config/hooks/lines";

const GameCanvas = () => {
  const { lines } = useLines();
  const [actualLine, setActualLine] = useState(0);
  const layerRef = useRef();
  const eraserRef = useRef();
  const itemsRef = useRef();
  const width = window.innerWidth;
  const height = window.innerHeight;
  const base = useMemo(
    () => (width > height ? width : height),
    [width, height]
  );
  // const items = useMemo(() => getItems(base, width, height), []);
  const { allItems: items } = useItems(null, {
    base: base,
    width: width,
    height: height,
  });

  //tableau des refs d'items
  const [elRefs, setElRefs] = useState([]);

  //tableau des images cachées

  useEffect(() => {
    console.log("newREFS");
    // crée une ref pour chaque item
    setElRefs((elRefs) =>
      Array(items.length)
        .fill()
        .map((_, i) => createRef())
    );
  }, [items]);

  //Envoi le contexte à chaque item pour calcul des pixels effacés (transparents)
  const handleVerif = () => {
    if (!lines.length) return;
    const ctx = layerRef.current.getContext();
    elRefs?.map((e) => e.current.verifyItem(ctx));
  };

  const handleClick = (e) => {
    const pos = e?.target?.getPointerPosition?.();
    pos && eraserRef?.current?.setPosition?.(pos);
    layerRef.current.draw();
  };

  return (
    <Stage
      onTouchStart={handleClick}
      onClick={(e) => handleClick(e)}
      width={base}
      height={base}
    >
      {/* background */}
      <Layer>
        <CoverImage
          background
          opacity={0.7}
          width={base}
          height={base}
          file="plaine.jpg"
        />
      </Layer>
      {/* items */}
      <Layer listening={false} ref={itemsRef}>
        {items.map((e, i) => (
          <Item key={i} id={i} {...e} ref={elRefs[i]} />
        ))}
      </Layer>
      {/* cover */}
      <Layer ref={layerRef}>
        <Group>
          <CoverImage
            //  opacity={0.4}
            width={base}
            height={base}
            file="plaine.jpg"
          />
          {lines.map((line, i) => (
            <Group key={i}>
              {/* //ligne de suppression des pixels sur l'image principale */}
              <Line
                perfectDrawEnabled={false}
                listening={false}
                key={i}
                points={line?.points}
                stroke="#ffffff"
                strokeWidth={55}
                tension={0.5}
                lineCap="round"
                globalCompositeOperation="destination-out"
              />
            </Group>
          ))}

          {/* <Group globalCompositeOperation="destination-in">
          
          </Group> */}
        </Group>
      </Layer>
      <DragCircle
        ref={eraserRef}
        actualLine={actualLine}
        setActualLine={setActualLine}
        handleVerif={handleVerif}
        initX={window.innerWidth / 2}
        initY={height / 2}
      />
    </Stage>
  );
};

export default GameCanvas;
