import Konva from "konva";
import React  from "react";
import { Image } from "react-konva";
import useImage from "use-image";

//Image principale de couverture qui masque les ITEMS
const CoverImage = ({ width, height, background, file, ...props }) => {
  const [image] = useImage(`/assets/background/${file}`, "Anonymous");

  return (
    <>
      <Image
        perfectDrawEnabled={false}
        listening={false}
        width={width}
        image={image}
        height={height}
        filters={background ? [Konva.Filters.Brighten] : []}
        brightness={-0.4}
        {...props}
      />
    </>
  );
};

export default CoverImage;
