import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Circle, Layer } from "react-konva";
import useLines from "../config/hooks/lines";

//Cercle à déplacer pour effacer l'image de couverture
const DragCircle = forwardRef(
  ({ handleVerif, initX, initY }, ref) => {
    const {lines, setLines, addLine} = useLines()
    const [x, setX] = useState(initX);
    const [y, setY] = useState(initY);
    const dragLayerRef = useRef();
    const layerRef = useRef();
    const [erase, setErase] = useState(false); //active ou non le gommage

    useImperativeHandle(ref, () => ({
      setPosition({ x, y }) {
        setX(x);
        setY(y);
        addLine({ points: [x, y]});
      },
    }));

    const handleMove = (e) => {
      if (!erase) {
        return;
      }
      const stage = e.target.getStage();
      const point = stage.getPointerPosition();
      const pos = e.target.getStage().getPointerPosition();
      let lastLine = lines.length
        ? lines?.[lines?.length - 1]
        : { points: [pos.x, pos.y] };
      // add point
      lastLine.points = lastLine.points.concat([point.x, point.y]);
      // replace last
      lines.splice(lines.length - 1, 1, lastLine);
      setLines(lines.concat());
    };
    const handleDragStart = (e) => {
      setErase(true);
      e.target.moveTo(dragLayerRef.current);
      // e.target.startDrag()
    };

    const handleDragEnd = (e) => {
      setErase(false);
      handleVerif();
      e.target.moveTo(layerRef.current);
    };

    return (
      <>
        <Layer ref={layerRef}>
          <Circle
            draggable
            onDragStart={(e) => handleDragStart(e)}
            onDragEnd={(e) => handleDragEnd(e)}
            onDragMove={(e) => {
              handleMove(e);
              const x = e.target.x();
              const y = e.target.y();
              setX(x);
              setY(y);
            }}
            x={x}
            y={y}
            radius={25}
            shadowBlur={15}
            shadowOpacity={.5}
            fill="white"
            globalAlpha={0.5}
            opacity={.8}
            perfectDrawEnabled={false}
          />
        </Layer>
        <Layer ref={dragLayerRef} />
      </>
    );
  }
);

export default DragCircle;
