import React, { useEffect } from "react";
import { Duration } from "luxon";
import { Box, Typography } from "@material-ui/core";
import { Timer } from "@material-ui/icons";

import useGame from "../../config/hooks/game";

const GameTimer = () => {
  const { isEnd, finalTimer, setFinalTimer, incrementTimer } = useGame();

  useEffect(() => {
    let interval;
    if (isEnd) {
      clearInterval(interval);
    } else {
      setFinalTimer(0);
      interval = setInterval(() => incrementTimer(), 1000);
    }
    return () => clearInterval(interval);
  }, [isEnd]);
  
  return (
    <Box mb={1} p={1} alignItems="center" display="flex">
      <Timer />
      <Typography variant="body1">
        {Duration.fromObject({ seconds: finalTimer }).toFormat("mm:ss")}
      </Typography>
    </Box>
  );
};

export default GameTimer;
